import React from "react"
import styles from "./styles.module.css"

const Frame = ({data}) => {
    return (
        <div className={styles.container}>
            <iframe src={data.url} height={data.height + 'px'} width={data.width + 'px'} frameBorder="0" />
        </div>
    )
}

export default Frame
