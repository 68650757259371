import React from "react"
import ImageParagraph from "../ImageParagraph"
import CardsParagraph from "../CardsParagraph"
import VideoParagraph from "../VideoParagraph"
import QuoteParagraph from "../QuoteParagraph"
import Carousel from "../Carousel"
import Frame from "../Frame"
import Banner from "../Banner"
import Description from "../Description"
import TextParagraph from "../TextParagraph"
import Accordion from "../Accordion"
import Markup from "../Markup"

const Switcher = ({data}) => {
    const type = data.type ? data.type.type : null

    switch (type) {
        case "ImageParagraph":
            return <ImageParagraph data={data} />

        case "CardsParagraph":
            return <CardsParagraph data={data} />

        case "VideoParagraph":
            return <VideoParagraph data={data} />

        case "QuoteParagraph":
            return <QuoteParagraph data={data} />

        case "Carousel":
            return <Carousel data={data} />

        case "Frame":
            return <Frame data={data} />

        case "Banner":
            return <Banner data={data} />

        case "Description":
            return <Description data={data} />

        case "TextParagraph":
            return <TextParagraph data={data} />

        case "Accordion":
            return <Accordion data={data} />

		case "Markup":
            return <Markup data={data} />

        default:
            return <div> I don't know what is it!</div>
    }
}

export default Switcher
