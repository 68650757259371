import React from "react"
import styles from "./styles.module.css"
import ReactMarkdown from "react-markdown"

const VideoParagraph = ({data}) => (
    <div className={styles.container}>
        {data.title &&
            <h1 className={styles.title}>
                <ReactMarkdown source={data.title} />
            </h1>
        }
        {data.text && <ReactMarkdown source={data.text} className={styles.text} />}
        {data.url &&
            <div className={styles.video}>
                <iframe src={data.url} title="video" frameBorder="0" width="500" height="300" />
            </div>
        }
    </div>
)

export default VideoParagraph
