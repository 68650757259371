import React from "react"
import ReactMarkdown from "react-markdown"
import styles from "./styles.module.css"

const QuoteParagraph = ({data}) => (
    <div className={styles.container} style={{background: data.background, color: data.color}}>
        <div className={styles.main}>
            <div>
                {data.title &&
                    <h1>
                        <ReactMarkdown source={data.title} />
                    </h1>
                }
                {data.text &&
                    <p className={styles.text}>{data.text}</p>
                }
                <div className={styles.person}>
                    <img src={data.imgCompany.publicURL} alt="" className={styles.imageCompany} />
                    <img src={data.imgPerson.publicURL} alt="" className={styles.imagePerson} />
                    <div>
                        <p className={styles.name}>{data.namePerson}</p>
                        <p className={styles.company}>{data.nameCompany}</p>
                    </div>
                </div>
            </div>
            {data.url &&
                <div className={styles.video}>
                    <iframe src={data.url} title="video" frameBorder="0" width="500" height="300" />
                </div>
            }
        </div>
    </div>
)

export default QuoteParagraph
