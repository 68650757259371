import React from "react"
import styles from "./styles.module.css"
import ReactMarkdown from "react-markdown"
import Button from "../Button"

const ImageParagraph = ({data}) => (
    <div className={styles.container}
        style={{background: data.background, color: data.color}}>
        <div className={styles.block}>
            <div className={styles.main}>
                {data.image && data.imgPosition === 'left' &&
                    <img src={data.image.publicURL} alt="" className={styles.imageLeft} />
                }
                <div className={data.btnText || data.image ? styles.text : styles.textFullWidth}>
                    {data.title &&
                        <h1 className={styles.title} style={{color: data.color}}>
                            <ReactMarkdown source={data.title} />
                        </h1>
                    }
                    {data.text &&
                        <div>
                            <ReactMarkdown source={data.text} />
                        </div>
                    }
                    {data.btnLink && data.btnText && data.image &&
                        <Button link={data.btnLink} text={data.btnText} style={{margin: "40px 0 0"}} />
                    }
                </div>
                {data.image && data.imgPosition === 'right' &&
                    <img src={data.image.publicURL} alt="" className={styles.imageRight} />
                }
                {data.btnLink && data.btnText && !data.image &&
                    <Button link={data.btnLink} text={data.btnText} style={{margin: "0 auto"}} />
                }
            </div>
        </div>
    </div>
)

export default ImageParagraph
