import React from "react"
import styles from "./styles.module.css"
import ReactMarkdown from "react-markdown"
import Button from "../Button"

const Card = ({title, text, image, btnText, btnLink, border, width}) => (
    <div style={width ? {width: `calc(100%/${width} - 20px*${width - 1}/${width})`} : {}}
        className={border ? styles.containerWithBorder : styles.container}>
        {image && <div className={styles.image} style={{backgroundImage: `url(${image.publicURL})`}} />}
        {title &&
                <h4 className={styles.title}><ReactMarkdown source={title} /></h4>
        }
        {text &&
            <ReactMarkdown source={text} className={styles.text} />
        }
        {btnText && btnLink &&
            <Button link={btnLink} text={btnText} style={{marginTop: 'auto'}} />
        }
    </div>
)

export default Card
