import React from "react"
import styles from "./styles.module.css"
import ReactMarkdown from "react-markdown"
import Button from "../Button"

const Description = ({data}) => (
    <div className={styles.container}>
        {data.title &&
            <h1 className={styles.title}>
                <ReactMarkdown source={data.title} />
            </h1>
        }
        {data.text &&
            <div className={styles.text}>
                <ReactMarkdown source={data.text} />
            </div>
        }
        {!!data.items.length &&
            <div className={styles.block}>
                {data.subtitle && <h4 className={styles.title}>
                    <ReactMarkdown source={data.subtitle} />
                </h4>}
                {data.image && <div className={styles.image} style={{backgroundImage: `url(${data.image.publicURL})`}} />}
                {data.items.map((item, index) =>
                    <div key={index} className={styles.item}>
                        {item.btnLink && item.btnText &&
                            <Button link={item.btnLink} text={item.btnText} style={{background: "#415dd4", color: "#fff"}} />
                        }
                        {item.title &&
                            <p className={styles.title} style={item.main ? {fontSize: '28px'} : {}}>
                                {item.title}
                            </p>
                        }
                        {item.text && <p className={styles.itemText}>{item.text}</p>}
                    </div>
                )}
                {data.btnLink && data.btnText &&
                    <Button link={data.btnLink} text={data.btnText} />
                }
            </div>
        }
    </div>
)

export default Description
