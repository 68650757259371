import React from "react"
import styles from "./styles.module.css"
import ReactMarkdown from "react-markdown"
import Button from "../Button"

const Banner = ({data}) => (
    <div className={styles.container}
        style={{backgroundColor: data.background, color: data.color}}>
        <div className={styles.main}>
            {data.image && data.imgPosition === 'left' &&
                <img src={data.image.publicURL} style={data.imgMaxHeight ? {maxHeight: data.imgMaxHeight + 'px'} : {}}
                    alt="" className={styles.imageLeft} />
            }
            <div className={styles.text}>
                {data.title &&
                    <ReactMarkdown source={data.title} className={styles.title} />
                }
                {data.btnLink && data.btnText && data.image &&
                    <Button link={data.btnLink} text={data.btnText} style={{margin: "40px 0 0"}} />
                }
            </div>
            {data.image && data.imgPosition === 'right' &&
                <img src={data.image.publicURL} style={data.imgMaxHeight ? {maxHeight: data.imgMaxHeight + 'px'} : {}}
                    alt="" className={styles.imageRight} />
            }
            {data.btnLink && data.btnText && !data.image &&
                <Button link={data.btnLink} text={data.btnText} style={{margin: "0 auto"}} />
            }
        </div>
    </div>
)

export default Banner
