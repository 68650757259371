import React from "react"
import ReactMarkdown from "react-markdown"
import styles from "./styles.module.css"
import Card from "../Card"
import Button from "../Button"

const CardsParagraph = ({data}) => (
    <div className={styles.container}>
        <div className={styles.block}>
            {data.image &&
                <img src={data.image.publicURL} alt="" className={styles.image} />
            }
            {data.title &&
                <h1 className={styles.title}>
                    <ReactMarkdown source={data.title} />
                </h1>
            }
            {data.text && <div className={styles.text}>
                <ReactMarkdown source={data.text} />
            </div>}
            <div className={styles.cards}>
                {data.cards.map((card, index) =>
                    <Card key={index}
                        title={card.title}
                        text={card.text}
                        image={card.image}
                        btnText={card.btnText}
                        btnLink={card.btnLink}
                        border={card.border}
                        width={data.width}
                    />
                )}
            </div>
            {data.btnLink && data.btnText &&
            <Button link={data.btnLink} text={data.btnText} style={{background: "#415dd4", color: "#fff"}} />
        }
        </div>
    </div>
)

export default CardsParagraph
