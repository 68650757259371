import React from "react"
import styles from "./styles.module.css"
import ReactMarkdown from "react-markdown"

const TextParagraph = ({data}) => (
    <div className={styles.container}>
        <div className={styles.block}>
            {data.title &&
                <h1 className={styles.title}>
                    <ReactMarkdown source={data.title} />
                </h1>
            }
            {data.text &&
                <div className={styles.text}>
                    <ReactMarkdown source={data.text} />
                </div>
            }
            {!!data.items.length && data.items.map((item, index) =>
                <div key={index} className={styles.item}>
                    {item.title && <h3 className={styles.title}>
                        <ReactMarkdown source={item.title} />
                    </h3>}
                    {item.video &&
                        <div className={styles.video}>
                            <iframe src={item.video} title="video" frameBorder="0" width="500" height="300" />
                        </div>
                    }
                    <div className={styles.content}>
                        {item.image &&
                            <img src={item.image.publicURL} alt="" className={styles.image} />
                        }
                        {item.text &&
                            <div>
                                <ReactMarkdown source={item.text} />
                            </div>
                        }
                    </div>
                </div>
            )}
        </div>
    </div>
)

export default TextParagraph
