import React, {useState, useRef} from "react"
import ReactMarkdown from "react-markdown"
import styles from "./styles.module.css"

const Panel = ({text, title}) => {
    const [open, toggleOpen] = useState(false)
    const expanded = useRef(null)

    const handleClick = () => {
        const el = expanded.current

        if (open) {
            el.style.height = 0
        } else {
            el.style.height = el.scrollHeight + 20 + "px"
        }
        
        toggleOpen(!open)
    }

    return (
        <div className={styles.item}>
            <div onClick={handleClick} className={styles.subtitle}>
                {title}
            </div>
            <div ref={expanded} className={styles.expanded}>
                <ReactMarkdown source={text} />
            </div>
        </div>
    )
}

export default Panel
