import React, {useEffect, useState} from "react"
import ReactMarkdown from "react-markdown"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"
import styles from "./styles.module.css"

const settings = {
    className: "carousel",
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
    slidesToShow: 5,
    slidesToScroll: 1
}

const Carousel = ({data}) => {
    const [isMobile, setIsMobile] = useState(null)

    useEffect(() => {
        const mobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

        if (isMobile === null) setIsMobile(mobile)
    }, [isMobile])

    return (
        <div className={styles.container}>
            <div className={styles.block}>
                {data.title &&
                    <h1 className={styles.title}>
                        <ReactMarkdown source={data.title} />
                    </h1>
                }
                <Slider {...settings} slidesToShow={isMobile ? 3 : 5}>
                    {data.cards.map((item, index) =>
                        <div key={index} className={styles.image}>
                            <img src={item.image.publicURL} alt="" />
                        </div>
                    )}
                </Slider>
            </div>
        </div>
    )
}

export default Carousel
