import React from "react"
import styles from "./styles.module.css"

const Markup = ({data}) => (
    <div className={styles.container}>
		<div dangerouslySetInnerHTML={{__html: data.text}} />
    </div>
)

export default Markup
