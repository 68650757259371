import React from "react"
import styles from "./styles.module.css"
import ReactMarkdown from "react-markdown"
import Panel from "./Panel"

const Accordion = ({data}) => (
    <div className={styles.container}>
        {data.title &&
            <h1 className={styles.title}>
                <ReactMarkdown source={data.title} />
            </h1>
        }
        {data.text &&
            <div className={styles.text}>
                <ReactMarkdown source={data.text} />
            </div>
        }
        {!!data.items.length &&
            <div>
                {data.items.map((item, index) =>
                    <Panel key={index} title={item.title} text={item.text} />
                )}
            </div>
        }
    </div>
)

export default Accordion
